"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
var _TypingStatusIndicator = _interopRequireDefault(require("./TypingStatusIndicator"));
var _UserAvatar = _interopRequireDefault(require("./UserAvatar"));
var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default,
    TypingStatusIndicator: _TypingStatusIndicator.default,
    UserAvatar: _UserAvatar.default
  },
  props: ['network', 'user', 'nicklist'],
  computed: {
    dataAttributes: function dataAttributes() {
      var attrs = Object.create(null);
      attrs['data-nick'] = this.user.nick.toLowerCase();
      attrs['data-host'] = this.user.host.toLowerCase();
      if (this.user.account) {
        attrs['data-account'] = this.user.account.toLowerCase();
      }
      var userMode = this.nicklist.buffer.userMode(this.user);
      if (userMode) {
        attrs['data-mode'] = userMode;
      }
      return attrs;
    },
    userColour: function userColour() {
      if (this.nicklist.useColouredNicks) {
        return this.user.getColour();
      }
      return '';
    },
    userModePrefix: function userModePrefix() {
      return this.nicklist.buffer.userModePrefix(this.user);
    },
    userTypingState: function userTypingState() {
      var status = this.user.typingStatus(this.nicklist.buffer.name).status;
      // console.log('userTypingState', this.user.nick, status);
      return status;
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["NicklistUser"]
window._kiwi_exports.components.NicklistUser = exports.default ? exports.default : exports;
