"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-sidebar-buffersettings",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('tabbed-view', {
    ref: "tabs"
  }, [_c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('settings'),
      "focus": true,
      "name": "settings"
    }
  }, [_vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-sidebar-settings",
    class: {
      'kiwi-sidebar-settings-disabled': !_vm.areWeAnOp
    }
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t('channel_settings')) + " "), !_vm.areWeAnOp ? _c('span', {
    staticClass: "kiwi-channelsettings-disabled"
  }, [_vm._v(" " + _vm._s(_vm.$t('mods_only')) + " "), _c('i', {
    staticClass: "fa fa-lock",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()]), _c('hr'), _c('channel-info', {
    attrs: {
      "buffer": _vm.buffer
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "kiwi-sidebar-settings"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('side_settings')))]), _c('hr'), _c('form', {
    staticClass: "u-form"
  }, [_c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('side_joins')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShowJoinParts,
      expression: "settingShowJoinParts"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShowJoinParts) ? _vm._i(_vm.settingShowJoinParts, null) > -1 : _vm.settingShowJoinParts
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShowJoinParts,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShowJoinParts = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && (_vm.settingShowJoinParts = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShowJoinParts = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('side_topics')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShowTopics,
      expression: "settingShowTopics"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShowTopics) ? _vm._i(_vm.settingShowTopics, null) > -1 : _vm.settingShowTopics
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShowTopics,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShowTopics = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context2;
            $$i > -1 && (_vm.settingShowTopics = (0, _concat.default)(_context2 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context2, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShowTopics = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('side_nick_changes')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShowNickChanges,
      expression: "settingShowNickChanges"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShowNickChanges) ? _vm._i(_vm.settingShowNickChanges, null) > -1 : _vm.settingShowNickChanges
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShowNickChanges,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShowNickChanges = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context3;
            $$i > -1 && (_vm.settingShowNickChanges = (0, _concat.default)(_context3 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context3, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShowNickChanges = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('side_mode_changes')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShowModeChanges,
      expression: "settingShowModeChanges"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShowModeChanges) ? _vm._i(_vm.settingShowModeChanges, null) > -1 : _vm.settingShowModeChanges
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShowModeChanges,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShowModeChanges = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context4;
            $$i > -1 && (_vm.settingShowModeChanges = (0, _concat.default)(_context4 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context4, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShowModeChanges = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('settings_share_typing')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShareTyping,
      expression: "settingShareTyping"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShareTyping) ? _vm._i(_vm.settingShareTyping, null) > -1 : _vm.settingShareTyping
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShareTyping,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShareTyping = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context5;
            $$i > -1 && (_vm.settingShareTyping = (0, _concat.default)(_context5 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context5, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShareTyping = $$c;
        }
      }
    }
  })])])])]), _c('tabbed-tab', {
    attrs: {
      "header": _vm.$t('access'),
      "name": "access"
    }
  }, [!_vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-sidebar-settings"
  }, [_c('h3', [!_vm.areWeAnOp ? _c('span', {
    staticClass: "kiwi-channelsettings-disabled"
  }, [_vm._v(" " + _vm._s(_vm.$t('mods_only')) + " "), _c('i', {
    staticClass: "fa fa-lock",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()]), _c('hr')]) : _vm._e(), _vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-sidebar-settings"
  }, [_c('a', {
    staticClass: "u-link kiwi-sidebar-accesstab",
    class: {
      'kiwi-sidebar-accesstab--active': _vm.accessTab === 'banlist'
    },
    on: {
      "click": function click($event) {
        _vm.accessTab = 'banlist';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('banned')) + " ")]), _c('a', {
    staticClass: "u-link kiwi-sidebar-accesstab",
    class: {
      'kiwi-sidebar-accesstab--active': _vm.accessTab === 'invitelist'
    },
    on: {
      "click": function click($event) {
        _vm.accessTab = 'invitelist';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invited')) + " ")]), _vm.accessTab === 'banlist' ? _c('channel-banlist', {
    attrs: {
      "buffer": _vm.buffer
    }
  }) : _vm._e(), _vm.accessTab === 'invitelist' ? _c('channel-invitelist', {
    attrs: {
      "buffer": _vm.buffer
    }
  }) : _vm._e()], 1) : _vm._e()]), _vm._l(_vm.pluginUiElements, function (item) {
    return _c('tabbed-tab', {
      key: item.id,
      attrs: {
        "header": item.title(),
        "name": item.tabName
      }
    }, [_c(item.component, _vm._b({
      tag: "component",
      attrs: {
        "network": _vm.network,
        "buffer": _vm.buffer,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', item.props, false))], 1);
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;